.community-item .community-item-container {
  background-color: white;
  margin-top: 7px;
  padding: 12px;
  padding-bottom: 0;
}
.community-item .community-item-container .header {
  display: flex;
  position: relative;
  align-items: center;
}
.community-item .community-item-container .header .avatar {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.community-item .community-item-container .header .username {
  font-size: 0.26rem;
  color: #282828;
  display: flex;
  align-items: center;
}
.community-item .community-item-container .header .username .name-remark {
  background-color: #F6F6F6;
  border-radius: 3px;
  color: #999;
  font-size: 0.2rem;
  padding: 0 3px;
  margin-left: 5px;
  display: inline-block;
}
.community-item .community-item-container .header .time {
  font-size: 0.2rem;
  color: #999;
  margin-top: 5px;
}
.community-item .community-item-container .header .gold {
  position: absolute;
  top: 0;
  right: 0;
  width: 51px;
}
.community-item .community-item-container .header .gold .number {
  position: relative;
  top: -22px;
  left: 7px;
  font-size: 0.14rem;
  color: #8B4BEF;
  font-weight: 600;
}
.community-item .community-item-container .title {
  font-size: 0.32rem;
  font-weight: 600;
  color: #282828;
  margin-top: 11px;
}
.community-item .community-item-container .content {
  margin-top: 11px;
  color: #666;
  font-size: 0.28rem;
  line-height: 1.7;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 1.34rem;
}
.community-item .community-item-container .content a {
  color: #999;
  display: true;
  pointer-events: none;
}
.community-item .community-item-container .allcontent {
  -webkit-line-clamp: revert;
  max-height: fit-content;
}
.community-item .community-item-container .video {
  width: 225px;
  height: 126px;
  margin-top: 15px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.community-item .community-item-container .video video {
  border-radius: 10px;
}
.community-item .community-item-container .video .video-react-poster {
  object-fit: fill;
}
.community-item .community-item-container .video .video-react-control-bar {
  visibility: hidden;
}
.community-item .community-item-container .video .video-react.video-react-fluid,
.community-item .community-item-container .video .video-react.video-react-16-9,
.community-item .community-item-container .video .video-react.video-react-4-3 {
  height: 100%;
}
.community-item .community-item-container .video .video-react-controls-enabled {
  padding-top: 0 !important;
}
.community-item .community-item-container .video .playbtn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.community-item .community-item-container .video .playbtn img {
  width: 50px;
  height: 50px;
}
.community-item .community-item-container .wap .image {
  width: 225px;
  height: 127px;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 10px;
  vertical-align: middle;
}
.community-item .community-item-container .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 51px;
}
.community-item .community-item-container .bottom .ip {
  font-size: 0.2rem;
  color: #999;
  padding: 18px 0;
}
.community-item .community-item-container .bottom .action {
  display: flex;
  align-items: center;
}
.community-item .community-item-container .bottom .action .btn {
  color: #ccc;
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.community-item .community-item-container .bottom .action .btn .number {
  font-size: 0.22rem;
  color: #999;
  margin-left: 4px;
}
.community-item .community-item-container .question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 12px;
}
.community-item .community-item-container .question .time {
  color: #999;
  font-size: 0.22rem;
}
.community-item .community-item-container .question .time .footmark-type {
  padding: 1.5px 3px;
  border-radius: 3px;
  margin-left: 5px;
}
.community-item .community-item-container .question .answer {
  color: #FFC000;
  font-size: 0.24rem;
  border: 0.5px solid #FFC000;
  border-radius: 13.5px;
  padding: 3px 12px;
}
.community-item .community-item-container .reply-container {
  border-radius: 10px;
  overflow: hidden;
}
.community-item .community-item-container .reply-container .reply-item {
  padding: 14px 11px 0;
  background-color: #F5F6F8;
}
.community-item .community-item-container .reply-container .reply-item .userinfo img {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  border-radius: 7.5px;
}
.community-item .community-item-container .reply-container .reply-item .userinfo span {
  font-size: 0.24rem;
  color: #282828;
  font-weight: 500;
  vertical-align: middle;
}
.community-item .community-item-container .reply-container .reply-item .reply-content {
  padding-top: 10px;
  line-height: 1.8;
  color: #666;
  font-size: 0.26rem;
  overflow: hidden;
  word-wrap: break-word;
}
.community-item .community-item-container .reply-container .reply-item .action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 15px;
}
.community-item .community-item-container .reply-container .reply-item .action .ip {
  font-size: 0.2rem;
  color: #999;
}
.community-item .community-item-container .reply-container .reply-item .action .like {
  font-size: 0.22rem;
  color: #999;
  display: flex;
  align-items: center;
}
.community-item .community-item-container .reply-container .reply-item .action .like .number {
  padding-right: 15px;
}
.community-item .community-item-container .reply-container .reply-item .line {
  border-top: 0.5px dashed #999;
}
.community-item .community-item-container .reply {
  background-color: #FBFBFB;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 12px;
}
.community-item .community-item-container .reply .username {
  font-size: 0.24rem;
  color: #282828;
  font-weight: 600;
  line-height: 12px;
}
.community-item .community-item-container .reply .reply-contents {
  font-size: 0.24rem;
  color: #999;
  margin-left: 10px;
  line-height: 12px;
}
.community_sort {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 999;
}
.community_sort .sort-container {
  position: absolute;
  background-color: #060E1D;
  display: inline-block;
  width: 85px;
  height: 132px;
  top: 130px;
  right: 15px;
  border-radius: 8px;
}
.community_sort .sort-container .sort-list .sort-item {
  padding-top: 17px;
  color: white;
  font-size: 0.26rem;
  text-align: center;
}
