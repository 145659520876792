.dialog {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.dialog-mask {
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
}
.dialog-content {
  position: relative;
  z-index: 10003;
}
.dialog-toast {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 15px 20px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: fade-in;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}
.dialog-toast .toast-icon {
  font-size: 32px;
}
.dialog-toast .toast-text {
  font-size: 16px;
}
.dialog-toast .toast-text:nth-child(2) {
  margin-top: 5px;
}
.dialog-modal {
  border-radius: 8px;
  min-width: 70vw;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.25s;
}
.dialog-modal .modal-body {
  margin-top: -20px;
  max-height: 50vh;
  overflow-y: scroll;
  padding-top: 20px;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px;
}
.dialog-modal .modal-title {
  font-size: 18px;
  color: #000;
  margin-top: 20px;
  text-align: center;
  padding: 0 20px;
}
.dialog-modal .modal-content {
  margin-top: 20px;
  color: #333;
  font-size: 15px;
  padding: 0 0.3rem;
}
.dialog-modal .modal-content .voucher-pop-desc {
  text-align: left;
}
.dialog-modal .modal-content .sign-record-desc {
  text-align: left;
}
.dialog-modal .modal-content .sign-record-desc span {
  color: #ffc000;
}
.dialog-modal .modal-content .exchange-desc {
  text-align: left;
  color: #666;
  font-size: 13px;
}
.dialog-modal .modal-btns {
  border-top: 1px solid #eee;
  display: flex;
}
.dialog-modal .modal-btns .know {
  display: inline-block;
  flex-grow: 0;
  flex-basis: 120px;
  margin: 0 auto;
  height: 44px;
  background-color: #54C5CD;
  color: white;
  border-radius: 22px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1;
}
.dialog-modal .modal-btn {
  flex: 1;
  padding: 12.5px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #000;
  border-left: 1px solid #eee;
}
.dialog-modal .modal-btn:first-child {
  border-left: none;
}
.dialog-modal .modal-btn.success {
  color: #108ee9;
}
.dialog-modal .modal-btns.column {
  flex-direction: column;
}
.dialog-modal .modal-btns.column .modal-btn {
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #333;
  border-left: none;
  border-top: 1px solid #eee;
}
.dialog-modal .modal-btns.column .modal-btn:first-child {
  border-top: none;
}
.dialog-modal .know {
  border-top: none;
}
.dialog.enter {
  opacity: 0;
}
.dialog.enter .dialog-modal {
  transform: scale(1.25, 1.25);
}
.dialog.enter-active,
.dialog.enter-done,
.dialog.exit {
  opacity: 1;
}
.dialog.enter-active .dialog-modal,
.dialog.enter-done .dialog-modal,
.dialog.exit .dialog-modal {
  transform: scale(1, 1);
}
.dialog.exit-active {
  opacity: 0;
}
.dialog.exit-active .dialog-modal {
  transform: scale(1.25, 1.25);
}
