.message-index .message-group {
  padding: 10px;
  padding-bottom: 0;
}
.message-index .message-group .item {
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.message-index .message-group .item:last-child {
  border-bottom: none;
}
.message-index .message-group .item .icon {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}
.message-index .message-group .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.message-index .message-group .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px 0;
}
.message-index .message-group .item .info .name {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
}
.message-index .message-group .item .info .name .empty {
  color: #999;
}
.message-index .message-sys .sys-title {
  background-color: #ccc;
  padding: 10px 15px;
}
.message-comment {
  padding: 10px;
}
.message-comment .item {
  display: flex;
  background-color: #fff;
  transition: all 0.25s;
  max-height: 100px;
}
.message-comment .item .entity {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.message-comment .item .icon {
  width: 65px;
  height: 65px;
  overflow: hidden;
  border-radius: 50%;
}
.message-comment .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.message-comment .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
.message-comment .item .info .name {
  font-size: 15px;
  color: #000000;
}
.message-comment .item .info .desc {
  font-size: 13px;
  color: #999;
}
.message-comment .item .info .text {
  color: #666;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.message-comment .item .time {
  font-size: 13px;
}
.message-comment .item .operate {
  width: 0;
  background-color: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
}
.message-comment .item.operable .operate {
  width: 50px;
  margin-left: 10px;
}
.message-comment .item.exit-active {
  max-height: 0;
  opacity: 0;
}
.message-qa {
  padding: 10px;
}
.message-qa .item {
  display: flex;
  background-color: #fff;
  transition: all 0.25s;
  max-height: 100px;
}
.message-qa .item .entity {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.message-qa .item .icon {
  width: 65px;
  height: 65px;
  overflow: hidden;
  border-radius: 50%;
}
.message-qa .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.message-qa .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
.message-qa .item .info .name {
  font-size: 15px;
  color: #000000;
}
.message-qa .item .info .desc {
  font-size: 13px;
  color: #999;
}
.message-qa .item .info .text {
  color: #666;
}
.message-qa .item .time {
  font-size: 13px;
}
.message-qa .item .operate {
  width: 0;
  background-color: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
}
.message-qa .item.operable .operate {
  width: 50px;
  margin-left: 10px;
}
.message-qa .item.exit-active {
  max-height: 0;
  opacity: 0;
}
.sys-mess-index {
  padding: 10px;
  border-bottom: 1px solid #ededed;
}
.sys-mess-data {
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
}
.sys-mess-data .data-head {
  display: flex;
  align-items: center;
  padding-top: 5px;
}
.sys-mess-data .data-head .text {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  flex: 1 1;
}
.sys-mess-data .tips {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sys-mess-data .operate {
  color: #4988E8;
}
.message-new {
  padding: 0.3rem;
}
.message-infobox {
  background-color: #f5f6f8;
  min-height: calc(100vh - 90px);
}
.message-infobox .infobox-body {
  padding: 12.5px;
  padding-bottom: 90px;
}
.message-infobox .infobox-body .left-content {
  display: flex;
  padding-bottom: 0.5rem;
}
.message-infobox .infobox-body .left-content .avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}
.message-infobox .infobox-body .left-content .avatar img {
  display: block;
  width: 100%;
  height: 100%;
}
.message-infobox .infobox-body .left-content .info {
  padding-top: 0.2rem;
  max-width: 80%;
}
.message-infobox .infobox-body .left-content .info.type-game-message {
  flex: 1 1;
}
.message-infobox .infobox-body .left-content .info .nickname {
  font-size: 0.24rem;
  color: #282828;
  padding-bottom: 0.2rem;
}
.message-infobox .infobox-body .left-content .info .content-index {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
}
.message-infobox .infobox-body .left-content .info .game_info .item-base {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 0.2rem;
}
.message-infobox .infobox-body .left-content .info .game_info .item-base .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.message-infobox .infobox-body .left-content .info .game_info .item-base .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.message-infobox .infobox-body .left-content .info .game_info .item-base .text {
  flex: 1 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
}
.message-infobox .infobox-body .left-content .info .game_info .item-base .text .name {
  font-weight: bold;
  font-size: 0.3rem;
  color: #282828;
}
.message-infobox .infobox-body .left-content .info .game_info .item-base .text .info1 {
  font-size: 0.2rem;
}
.message-infobox .infobox-body .left-content .info .game_info .desc {
  padding-top: 0.1rem;
  border-top: 1px solid #CFCFCF;
  padding-bottom: 0.1rem;
  font-size: 0.24rem;
}
.message-infobox .infobox-body .left-content .info .game_info .operate {
  color: #3F7EE6;
}
.message-infobox .infobox-body .left-content .info .text-info {
  color: #282828;
}
.message-infobox .infobox-body .left-content .info .text-info .item-base {
  font-size: 0.24rem;
}
.message-infobox .infobox-body .right-content {
  display: flex;
  padding-bottom: 0.5rem;
  justify-content: flex-end;
}
.message-infobox .infobox-body .right-content .avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin-left: 10px;
}
.message-infobox .infobox-body .right-content .avatar img {
  display: block;
  width: 100%;
  height: 100%;
}
.message-infobox .infobox-body .right-content .info {
  padding-top: 0.2rem;
  max-width: 80%;
}
.message-infobox .infobox-body .right-content .info .nickname {
  font-size: 0.24rem;
  color: #282828;
  padding-bottom: 0.2rem;
  text-align: right;
}
.message-infobox .infobox-body .right-content .info .content-index {
  background-color: #54C5CD;
  border-radius: 8px;
  padding: 10px;
  color: #282828;
}
.message-infobox .infobox-body .right-content .info .text-info {
  color: #282828;
}
.message-infobox .info-operate {
  position: fixed;
  z-index: 999;
  bottom: 0;
  background-color: #fff;
  left: 0;
  right: 0;
  padding-bottom: env(safe-area-inset-bottom);
}
.message-infobox .info-operate .container {
  height: 90px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.message-infobox .info-operate .container .input {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  background-color: #f6f6f6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 0.8rem;
  padding: 0 10px;
}
.message-infobox .info-operate .container .input input {
  flex: 1 1;
  width: 0;
  padding: 5px;
  background: none;
  border: none;
  font-size: 16px;
}
.message-infobox .info-operate .container .btn {
  background-color: #54C5CD;
  font-size: 16px;
  margin: 0 5px;
  border-radius: 20px;
  color: #fff;
  transition: all 0.1s;
  text-align: center;
  height: 0.8rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
